import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { FullPageLayoutComponent } from './components/full-page-layout/full-page-layout.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { MainToolbarComponent } from './components/main-toolbar/main-toolbar.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { environment } from '@env';
import { MainLayoutSpinnerComponent } from './components/main-layout-spinner/main-layout-spinner.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

import localeIt from '@angular/common/locales/it';
import { ErrorDetailsDialogComponent } from './components/error-details-dialog/error-details-dialog.component';

import { RouterModule } from '@angular/router';


registerLocaleData(localeIt);

@NgModule({
  declarations: [
    FullPageLayoutComponent,
    MainLayoutComponent,
    MainToolbarComponent,
    AlertDialogComponent,
    MainLayoutSpinnerComponent,
    SnackBarComponent,
    ConfirmDialogComponent,
    ErrorDetailsDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule,
    AngularFireModule.initializeApp(environment.google),
    AngularFireAuthModule
  ],
  exports: [
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule,
    AngularFireModule,
    AngularFireAuthModule
  ],
  providers: [
    DecimalPipe,
    { provide: LOCALE_ID, useValue: 'it-IT' },
  ]
})
export class SharedModule { }
