<h2 class="red" mat-dialog-title>
    {{ info.title }}
</h2>
<mat-dialog-content class="mat-typography">
    <p class="body">
        {{ info.body }}
    </p>
    <ng-container *ngIf="info.details">
        <pre>{{info.details | json}}</pre>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <span class="app">{{info.appName}} v{{info.appVersion}} @ {{info.environmentId}}</span>
    <span fxFlex>&nbsp;</span>
    <button mat-button mat-stroked-button color="accent" (click)="copy()">
        {{copied?"Copiato negli Appunti":"Copia negli Appunti"}}
        <mat-icon *ngIf="copied">done</mat-icon>
    </button>
    <button mat-button mat-raised-button [mat-dialog-close]="true" color="accent" cdkFocusInitial>
        Ok
    </button>
</mat-dialog-actions>
<textarea #copyInput class="copyInput">{{info | json}}</textarea>