<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <p class="body">
    {{ data.body }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <span fxFlex>&nbsp;</span>
  <button mat-button mat-raised-button [mat-dialog-close]="true" color="accent" cdkFocusInitial>
    Ok
  </button>
</mat-dialog-actions>