import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { User } from '@shared/models/user.model';
import { CurrentUserService } from '@core/services/current-user/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserResolver implements Resolve<User> {

  constructor(private currentUserService: CurrentUserService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.currentUserService.currentUser.pipe(filter((user) => user !== null)).pipe(first()) as Observable<User>;
  }
}

