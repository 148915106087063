import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  private packageJson = require('/package.json');

  constructor() { }

  getAppName(): string {
    return this.packageJson.name;
  }

  getAppVersion(): string {
    return this.packageJson.version;
  }
}
