import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppInfoService } from '@core/services/app-info/app-info.service';
import { environment } from '@env';
import { User } from '@shared/models/user.model';

interface DebugInfo {
  environmentId: string;
  appName: string;
  appVersion: string;

  userAgent: string;

  title: string;
  body: string;
  details?: object;

  timestamp: Date;
  url: string;
}

@Component({
  selector: 'app-error-details-dialog',
  templateUrl: './error-details-dialog.component.html',
  styleUrls: ['./error-details-dialog.component.scss']
})
export class ErrorDetailsDialogComponent {

  public info: DebugInfo;

  public copied: boolean = false;

  @ViewChild("copyInput", { static: true })
  copyInputElement!: ElementRef<HTMLTextAreaElement>;

  constructor(@Inject(MAT_DIALOG_DATA) data: { title: string, body: string, details?: object }, appInfoService: AppInfoService) {
    this.info = {
      environmentId: environment.id,
      appName: appInfoService.getAppName(),
      appVersion: appInfoService.getAppVersion(),

      userAgent: navigator.userAgent,

      timestamp: new Date(),
      url: window.location.href,

      ...data
    };
  }

  public copy() {
    const onCopyFail = () => {
      alert("Non è stato possibile copiare il testo negli appunti... Seleziona e copia tutte le informazioni manualmente.");
    }
    try {
      this.copyInputElement.nativeElement.select();
      this.copied = document.execCommand("copy");
      if (!this.copied) {
        onCopyFail();
      }
    } catch (e) {
      onCopyFail();
    }
  }
}
