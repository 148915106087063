import { NgModule } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';

import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

import { CurrentUserResolver } from '@core/resolvers/current-user/current-user.resolver';
import { FullPageLayoutComponent } from '@shared/components/full-page-layout/full-page-layout.component';
import { MainLayoutComponent } from '@shared/components/main-layout/main-layout.component';


// tslint:disable-next-line:no-string-literal
const redirectUnauthorizedToLogin = (next: ActivatedRoute) => redirectUnauthorizedTo('/auth/login?returnUrl=' + encodeURIComponent((next as any)['_routerState'].url));

const routes: Routes = [
  {
    path: '',
    component: FullPageLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
      { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'workspace', loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule) }
    ],
    runGuardsAndResolvers: 'always',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    resolve: {
      currentUser: CurrentUserResolver
    }
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

