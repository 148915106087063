import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ErrorDetailsDialogComponent } from '@shared/components/error-details-dialog/error-details-dialog.component';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private static DURATION = 2500;
  private static POSITION: MatSnackBarVerticalPosition = 'bottom';

  constructor(private matSnackBar: MatSnackBar, private matDialog: MatDialog) { }

  showInProgress(message: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, type: 'in-progress' }, duration: 0, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showInfo(message: string, action?: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, action: action ?? 'OK', type: 'info' }, duration: 0, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showSuccess(message: string, action?: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, action: action ?? 'OK', type: 'success' }, duration: SnackBarService.DURATION, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showWarning(message: string, action?: string): Promise<void> {
    return this.matSnackBar.openFromComponent(SnackBarComponent, { data: { message, action: action ?? 'OK', type: 'warning' }, duration: 0, verticalPosition: SnackBarService.POSITION }).onAction().toPromise();
  }

  showError(message?: string, details?: object): Promise<void> {
    this.matSnackBar.dismiss();
    const msg = message ?? 'Spiacente, si è verificato un errore.';
    return this.matDialog.open(ErrorDetailsDialogComponent, {
      data: {
        title: "Errore",
        body: msg,
        details
      }
    }).afterClosed().toPromise();
  }

}
