import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private matDialog: MatDialog) { }

  open(title: string, body: string) {
    return this.matDialog.open(ConfirmDialogComponent, { data: { title, body } });
  }
}
