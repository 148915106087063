export const environment = {
  id: 'air-survey-dev',
  production: false,
  serviceWorkerEnabled: true,
  google: {
    appId: '1:648857518391:web:d03b4aa0641d5337965ab2',
    apiKey: 'AIzaSyCB6QwsZ3kvCn_cmwOfJzPqRbDfp-BfEyM',
    authDomain: 'air-survey-dev.firebaseapp.com'
  },
  measurementsApiBaseUrl: "https://air-survey-measurements-backend-kozmzfrxgq-ew.a.run.app",
  svg2pngApiBaseUrl: "https://air-survey-svg2png-backend-kozmzfrxgq-ew.a.run.app"
};
