<mat-toolbar color="primary" class="mat-elevation-z2">
  <div fxLayout="row" fxFlex>
    <a class="logo-container" href="/" style="text-decoration: none">
      <img src="/assets/logo-drone-white.png" alt="" /><span fxHide.xs> Air Survey</span>
    </a>
    <div fxFlex>

    </div>
    <div fxLayout="row" fxLayoutAlign="center center" [matMenuTriggerFor]="menu">
      <span class="email" fxHide.xs>{{ currentUser.email }}</span>
      <img src="/assets/account-circle-black.png" class="avatar" />

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="resetPassword()">
          <mat-icon>password</mat-icon>
          <span>Cambia Password</span>
        </button>
        <button mat-menu-item routerLink="/auth/logout">
          <mat-icon>logout</mat-icon>
          <span>Esci</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>