import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from '@core/services/confirm-dialog/confirm-dialog.service';
import { SnackBarService } from '@core/services/snackbar/snack-bar.service';
import { User } from '@shared/models/user.model';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent {

  currentUser: User;

  constructor(
    private activatedRoute: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private snackBarService: SnackBarService,
    private angularFireAuth: AngularFireAuth
  ) {
    this.currentUser = this.activatedRoute.snapshot.data['currentUser'];
  }

  async resetPassword() {
    const result = await this.confirmDialogService.open("Cambio Password", `Vuoi cambiare la tua password?\nRiceverai all'indirizzo ${this.currentUser.email} una email con un link monouso per il reset della password.`).afterClosed().toPromise();
    if (result) {
      try {
        this.snackBarService.showInProgress('Invio in corso...');

        if (!this.currentUser.email) {
          throw new Error("Indirizzo email utente corrente non disponibile.");
        }

        await this.angularFireAuth.sendPasswordResetEmail(this.currentUser.email);

        this.snackBarService.showSuccess('L\'email per il cambio della password è stata inviata con successo.');

      } catch (e: any) {
        console.error(e);
        this.snackBarService.showError(e.error?.message ?? "Errore durante l'invio dell'email per il cambio password", e);
      }
    }
  }

}
