import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from '@shared/models/user.model';
import { map, Observable } from 'rxjs';

import 'firebase/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  currentUser: Observable<User | null>;

  constructor(private angularFireAuth: AngularFireAuth) {
    this.currentUser = this.angularFireAuth.user.pipe(map((firebaseUser) => firebaseUser !== null ? ({
      id: firebaseUser.uid,
      email: firebaseUser.email
    }) : null));

  }

}
